import React from "react"
import CaseStudyPageCircles from "./CaseStudyPageCircles"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const CaseStudyPageItem = ({
    dateTime,
    position,
    title,
    description,
    icon,
    displayYear,
    isItemLastInYear,
}) => {
    const lastItemClass = isItemLastInYear ? "CaseStudy-item-last" : ""

    return (
        <div className={`CaseStudy-item ${lastItemClass}`}>
            {displayYear && (
                <CaseStudyPageCircles year={dateTime.getFullYear()} />
            )}

            <div className={`CaseStudy-content ${position}`}>
                <p className="CaseStudy-date">
                    {dateTime.toLocaleString("default", {
                        month: "long",
                    })}
                </p>
                <h3 className="CaseStudy-step-title">{title}</h3>
                <div className="CaseStudy-desc">
                    {documentToReactComponents(description)}
                </div>
                <div className="CaseStudy-white-circle">
                    <img src={icon} className="CaseStudy-icon" alt="icon" />
                </div>
            </div>
        </div>
    )
}

export default CaseStudyPageItem
