import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { greyDarker, containerFixed } from "../constants/cssVariables"
import Section from "../components/Section/Section"
import SocialShare from "../components/SocialShare/SocialShare"
import PageHeader from "../components/PageHeader/PageHeader"
import arrow from "../assets/vector/vector-arrow.svg"
import "../pages/styles/case-study.css"
import CaseStudyPageItem from "../components/CaseStudyPageItem/CaseStudyPageItem"

const CaseStudyPage = ({ location }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulHome {
                edges {
                    node {
                        caseStudyPageSeoTitle
                        caseStudyPageSeoDescription
                        caseStudyPageTitle
                        caseStudyPageText {
                            json
                        }
                    }
                }
            }
            allContentfulCaseStudyItem(sort: { order: DESC, fields: date }) {
                edges {
                    node {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        icon {
                            file {
                                url
                            }
                        }
                        description {
                            json
                        }
                        settings {
                            position
                        }
                    }
                }
            }
        }
    `)

    const {
        caseStudyPageSeoTitle,
        caseStudyPageSeoDescription,
        caseStudyPageTitle,
        caseStudyPageText,
    } = data.allContentfulHome.edges[0].node

    const renderedYears = {}
    let prevYear

    return (
        <Layout location={location}>
            <SEO
                title={caseStudyPageSeoTitle}
                description={caseStudyPageSeoDescription}
            />
            <Section bgColor={greyDarker} container={containerFixed}>
                <div className="CaseStudy">
                    <PageHeader PageHeaderTitle={"Back to Homepage"} />
                    <div className="CaseStudy-inner">
                        <img
                            className="CaseStudy-arrow"
                            src={arrow}
                            alt="arrow icon"
                        />
                        <div className="CaseStudy-text-wrap">
                            <h1 className="CaseStudy-title">
                                {caseStudyPageTitle}
                            </h1>
                            <div className="CaseStudy-text">
                                {documentToReactComponents(
                                    caseStudyPageText.json
                                )}
                            </div>
                        </div>

                        {data.allContentfulCaseStudyItem.edges.map(
                            (item, index) => {
                                const {
                                    title,
                                    icon,
                                    settings,
                                    description,
                                    date,
                                } = item.node

                                let displayYear = false
                                const dateTime = new Date(date)
                                const year = dateTime.getFullYear()

                                if (!renderedYears[year]) {
                                    renderedYears[year] = true
                                    displayYear = true
                                }

                                const isItemLastInYear = prevYear === year
                                prevYear = year

                                return (
                                    <CaseStudyPageItem
                                        key={index}
                                        displayYear={displayYear}
                                        dateTime={dateTime}
                                        title={title}
                                        icon={icon.file.url}
                                        position={settings && settings.position}
                                        description={description.json}
                                        isItemLastInYear={isItemLastInYear}
                                    />
                                )
                            }
                        )}
                    </div>
                    <SocialShare location={location} />
                </div>
            </Section>
        </Layout>
    )
}

export default CaseStudyPage
