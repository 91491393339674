import React from "react"

const CaseStudyPageCircles = ({ year }) => {
    return (
        <div className="CaseStudy-circles">
            <div className="CaseStudy-circle"></div>
            <div className="CaseStudy-circle"></div>
            <div className="CaseStudy-circle"></div>
            <h3 className="CaseStudy-year">{year}</h3>
        </div>
    )
}

export default CaseStudyPageCircles
